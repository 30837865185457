import { useState, useCallback, useEffect } from 'react'
import { useInoContract } from './useContract'

const useCheckWLMode = (): any => {
  const contractMethod = useInoContract()
  const [data, setData] = useState(false)

  const getData = useCallback(async () => {
    if (contractMethod) {
      try {
        const result = await contractMethod.WHITELIST_MODE()
        setData(result)
      } catch (error) {
        console.error('useCheckWLMode', error)
      }
    }
  }, [contractMethod])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useCheckWLMode

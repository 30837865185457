/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useWeb3React } from '@web3-react/core'
import { Button, Col, Progress, Row } from 'antd'
import CountDownCustom from 'components/CountDown/CountDownCustom'
import { convertKeysToCamelCase } from 'helpers/CommonHelper'
import setListNFTs from 'hooks/setListNFTs'
import useCheckWLMode from 'hooks/useCheckWLMode'
import useCheckWhiteList from 'hooks/useCheckWhiteList'
import { useInoContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import Image from 'next/image'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { setModalLoading, setModalNftDetail } from 'state/modal/actions'
import { setMyNft } from 'state/nft/actions'
import styled from 'styled-components'
import { getTransactionReceiptMined } from 'utils'
import { ArrowBackIcon } from 'widgets'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1010px;
  margin: 0 auto 42px;
  padding: 0 16px;
`

const BoxContainer = styled.div`
  width: 100%;

  .body {
    position: relative;
    margin: 0 auto;

    .content {
      padding: 24px 0;
      margin: 0 auto;
      font-size: 24px;
      font-weight: bold;

      .header {
        gap: 12px;

        .phase {
          gap: 12px;

          img {
            width: 75px;
            aspect-ratio: 1/1;
          }

          .number-phase {
            position: relative;

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 20px;
            }
          }
        }

        .process-top {
          font-size: 18px;
          min-width: 200px;
          align-items: center;
          justify-content: space-between;

          ${({ theme }) => theme.mediaQueries.sm} {
            min-width: 256px;
          }
        }
      }

      .content-body {
        .content-left {
          display: flex;
          flex-direction: column;
          gap: 24px;
          grid-row: 2/3;

          ${({ theme }) => theme.mediaQueries.lg} {
            grid-row: 1/2;
            gap: 24px;
          }

          .info {
            min-height: 160px;
            aspect-ratio: 2/1;
            background-image: url(/images/background/box-info.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 0 8px;
            font-size: 16px;
            scale: 1.1;

            ${({ theme }) => theme.mediaQueries.sm} {
              min-height: 220px;
              font-size: 20px;
              background-position: 0 16px;
            }

            .info-item {
              justify-content: space-between;
              width: 100%;
              font-size: 18px;
              font-weight: 400;

              ${({ theme }) => theme.mediaQueries.md} {
                font-size: 24px;
              }

              span {
                font-weight: bold;
              }
            }
          }
          .price {
            min-height: 100px;
            background-image: url(/images/background/box-price.png);
            background-size: contain;
            background-repeat: no-repeat;

            ${({ theme }) => theme.mediaQueries.sm} {
              min-height: 132px;
            }

            .price-item {
              width: 100%;
              justify-content: center;
              margin: auto;
              height: 100%;
              align-items: center;
              font-weight: bold;
              gap: 12px;

              p {
                font-size: 24px;

                ${({ theme }) => theme.mediaQueries.md} {
                  font-size: 36px;
                }
              }

              img {
                width: 32px;
                aspect-ratio: 1/1;

                ${({ theme }) => theme.mediaQueries.sm} {
                  width: 48px;
                }
              }
            }
          }
          .btn-mint {
            width: 100%;
            margin: 0 auto;

            color: #fff;
            font-size: 14px;
            font-weight: 700;

            border: none;
            background-color: #5297ff70;
            padding: 8px;
            border-radius: 8px;

            ${({ theme }) => theme.mediaQueries.md} {
              font-size: 24px;
            }

            > div {
              background: #5297ff;
              border-radius: 6px;
              padding: 24px 32px;
              width: 100%;
              height: 100%;
              justify-content: center;
            }

            :hover {
              opacity: 0.8;
            }

            &:disabled {
              opacity: 0.5;
            }
          }
        }

        .content-right {
          grid-row: 1/2;
        }
      }
    }
  }
`

const PhaseBox = styled.div`
  background: #429cfe;
  height: fit-content;
  padding: 1px;

  clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  -webkit-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  -moz-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  -ms-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);

  > div {
    width: 100%;
    height: 100%;
    background: #1c2035;

    clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
    -webkit-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
    -moz-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
    -ms-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  }
`

const SaleBox = ({ box, fetchBox }: any) => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const myNftList = useSelector((state: AppState) => state.nft.myNftList)

  // Hook
  const { toastError, toastSuccess } = useToast()

  // Contract
  const contractMethod = useInoContract()

  const [isWL] = useCheckWhiteList(account)
  const [isWLMode] = useCheckWLMode()

  // useState
  const [loading, setLoading] = useState(false)
  const [isLoadedBanner, setIsLoadedBanner] = useState(false)

  const getBoxDataAfterBuy = async (interval: any) => {
    if (account) {
      setListNFTs({ limit: 100, owner: account }, (res) => {
        if (res?.data[0]?.id > myNftList?.[0]?.id || (myNftList?.length === 0 && res?.data?.length > 0)) {
          clearInterval(interval)

          dispatch(setMyNft(convertKeysToCamelCase(res?.data)))

          dispatch(
            setModalLoading({
              toggle: false,
            }),
          )

          setLoading(false)
          fetchBox()

          dispatch(
            setModalNftDetail({
              toggle: true,
              dataModal: {
                nft: convertKeysToCamelCase(res?.data[0]),
                type: 'BUY',
              },
            }),
          )
        }
      })
    }
  }

  const handleBuyNft = async () => {
    if (!loading) {
      if (contractMethod && box && account) {
        setLoading(true)

        try {
          await contractMethod
            .mintBox(box?.phase, box?.priceBox, '1', { from: account, value: box?.priceBox, gasPrice: '5000000000' })
            .then(async (res) => {
              let interval: any
              const receipt: any = await getTransactionReceiptMined(res?.hash, 500)

              if (receipt?.status) {
                dispatch(
                  setModalLoading({
                    toggle: true,
                  }),
                )
                interval = setInterval(() => getBoxDataAfterBuy(interval), 10000)
              }
            })
            .catch((error: any) => {
              const errMsg = error?.data?.message
              setLoading(false)
              toastError(errMsg || 'Buy box failed')
            })
        } catch (error: any) {
          const errMsg = error?.data?.message

          toastError(errMsg || 'Buy box failed')
          setLoading(false)
        }
      }
    }
  }

  return (
    <Wrapper>
      <BoxContainer>
        <Row>
          <div className="body">
            <div className="content flex flex-col gap-8">
              <div className="header grid xl:grid-cols-[591fr_475fr] lg:grid-cols-2 grid-cols-1 w-full">
                <div className="flex md:flex-row flex-col gap-4 justify-center lg:justify-start">
                  <PhaseBox>
                    <div className="flex items-center justify-center gap-2 py-3 px-10">
                      <p className="font-[500] text-[24px] md:text-[29px]">Phase</p>
                      <p className="text-[#FBEBB7] font-[500] text-[24px] md:text-[29px]">{box?.phase}</p>
                    </div>
                  </PhaseBox>

                  <div className="flex process flex-wrap">
                    <div className="process-top flex w-full">
                      <p className="text-base">Process</p>
                      <p className="md:text-[24px] text-base font-bold">
                        [{box?.currentBox}/{box?.totalBox}]
                      </p>
                    </div>
                    <Progress
                      percent={(box?.currentBox / box?.totalBox) * 100}
                      showInfo={false}
                      status="active"
                      strokeColor="#429CFE"
                    />
                  </div>
                </div>
                <div className="flex gap-3 items-center justify-center lg:justify-end lg:flex-row flex-col">
                  <p className="text-base text-[#F3E2D2] block">
                    {box?.startSale * 1000 > Date.now() ? 'Start in' : 'End in'}
                  </p>
                  <CountDownCustom
                    remains={box?.startSale * 1000 > Date.now() ? box?.startSale * 1000 : box?.endSale * 1000}
                  />
                </div>
              </div>

              <div className="flex flex-wrap items-center md:justify-between justify-center w-full border-[1px] border-solid border-[#FFFFFF33] rounded-[10px] md:px-8 md:py-6 p-3 bg-[#FFFFFF0D]">
                {[45, 30, 15, 7, 2.5, 0.5].map((x: number, idx: number) => (
                  <div key={x} className="flex flex-col items-center gap-2 px-4 py-2">
                    <p className="md:text-base text-sm text-[#5297FF] text-center">Rare {idx + 1}</p>
                    <p className="md:text-[20px] text-base font-bold text-center">{x}%</p>
                  </div>
                ))}
              </div>

              <div className="grid xl:grid-cols-[591fr_475fr] lg:grid-cols-2 grid-cols-1 xl:gap-[80px] gap-8 content-body mt-4">
                <div
                  className="relative content-right w-full lg:max-w-[590px] sm:max-w-[300px] max-w-[240px] aspect-[591/567] mx-auto bg-cover bg-center"
                  style={{ backgroundImage: isLoadedBanner ? '' : "url('/images/background/box-loading.png')" }}
                >
                  <Image
                    src="/images/background/box.png"
                    className={`${isLoadedBanner ? 'block' : 'hidden'} w-full h-full object-cover`}
                    layout="fill"
                    onLoadingComplete={() => {
                      setIsLoadedBanner(true)
                    }}
                    loading="lazy"
                  />
                </div>

                <div className="content-left md:mt-[-24px] mx-auto">
                  <div className="info">
                    <div className="flex flex-col sm:px-[48px] px-[42px] sm:py-[42px] py-6 w-full h-full justify-between">
                      <div className="flex info-item">
                        <p>Total supply</p>
                        <span>{box?.totalBox}</span>
                      </div>
                      <div className="flex info-item">
                        <p>Minted</p>
                        <span>{box?.currentBox}</span>
                      </div>
                      <div className="flex info-item">
                        <p>Per txn</p>
                        <span>1</span>
                      </div>
                    </div>
                  </div>
                  <div className="price">
                    <div className="flex price-item">
                      <img src="/images/icons/token.png" alt="" />
                      <p>{+box?.priceBox / 1e18} BNB / Box</p>
                    </div>
                  </div>
                  <Button
                    className="btn-mint"
                    disabled={
                      box?.startSale * 1000 > Date.now() ||
                      box?.endSale * 1000 < +Date.now() ||
                      box?.totalBox === box?.currentBox ||
                      loading ||
                      !account ||
                      (isWLMode && !isWL)
                    }
                    loading={loading}
                    onClick={handleBuyNft}
                  >
                    <div className="flex items-center gap-3">
                      <p>{isWLMode && !isWL ? 'You are not in whitelist' : 'Mint Now'}</p>
                      <ArrowBackIcon width={24} height={24} className="rotate-180" />
                    </div>
                  </Button>
                </div>
              </div>

              <Col span={24} lg={{ span: 12 }} />
            </div>
          </div>
        </Row>
      </BoxContainer>
    </Wrapper>
  )
}

export default SaleBox

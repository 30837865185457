import { getDateToDate } from 'utils/Date'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CountDownProps, CountDownType } from '.'

const WrapperCountDown = styled.div`
  p {
    display: contents;
  }

  .phase {
    gap: 16px;

    img {
      width: 75px;
    }

    .number-phase {
      position: relative;
    }
    .number-time {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      height: fit-content;
      align-items: center;
      display: flex;
      flex-direction: column;

      > div:nth-child(2) {
        font-size: 16px;
      }

      > div:first-child {
        font-size: 24px;
        font-weight: bold;
        color: #5297ff;
      }
    }
  }
`

const CountDownCustom: React.FC<CountDownProps> = ({ remains, className, style, ...props }) => {
  const [counter, updateCounter] = useState<CountDownType>({
    weekdays: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    totalRemain: 0,
  })

  useEffect(() => {
    let timer: any = 0
    function getTime() {
      const currentTime = new Date().getTime()
      const toTime = new Date(remains).getTime()
      if (toTime - currentTime > 0) {
        updateCounter({ ...(getDateToDate(currentTime, toTime) as any), totalRemain: toTime - currentTime })
      } else {
        updateCounter({
          weekdays: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          totalRemain: 0,
        })

        clearInterval(timer)
      }
    }
    getTime()

    timer = setInterval(() => getTime(), 1000)
    return () => {
      clearInterval(timer)
    }
  }, [remains])

  return (
    <WrapperCountDown className={className} style={style} {...props}>
      <div className="phase flex">
        <div className="number-phase">
          <img src="./images/background/time.png" alt="" />
          <div className="number-time">
            <div>{(counter.weekdays * 7 + counter.days).toString().padStart(2, '0')}</div>
            <div>Day</div>
          </div>
        </div>
        <div className="number-phase">
          <img src="./images/background/time.png" alt="" />
          <div className="number-time">
            <div>{counter.hours.toString().padStart(2, '0')}</div>
            <div>Hours</div>
          </div>
        </div>
        <div className="number-phase">
          <img src="./images/background/time.png" alt="" />
          <div className="number-time">
            <div>{counter.minutes.toString().padStart(2, '0')}</div>
            <div>Min</div>
          </div>
        </div>
        <div className="number-phase">
          <img src="./images/background/time.png" alt="" />
          <div className="number-time">
            <div>{counter.seconds.toString().padStart(2, '0')}</div>
            <div>Sec</div>
          </div>
        </div>
      </div>
    </WrapperCountDown>
  )
}

export default React.memo(CountDownCustom)
